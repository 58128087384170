import Home from "./pages/home";
import Concierge from "./pages/concierge";
import Error from "./pages/error";
import Contact from "./pages/contact";
import Root from "./pages/Root";
import { Navigate } from "react-router-dom";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />}>
      <Route index element={<Navigate to="/Home" replace={true} />} />
      <Route path="Services" element={<Navigate to="/Home" replace={true} />} />
      <Route path="Services/Concierge" element={<Concierge />} />
      <Route path="Home" element={<Home />} />
      <Route path="Contact" element={<Contact />} />
      <Route path="*" element={<Error />} />
      {/* ... etc. */}
    </Route>
  )
);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;