import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";
import Banner from "../components/banners/Banner";
import ServiceContainer from "../components/sections/serviceContainer";
import ContactUsSection from "../components/sections/contactUsSection";
import Accordions from "../components/sections/accordions";


function home() {
  const { i18n } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <div className={`App ${theme.palette.mode}`}>
        <Helmet>
          <title>
            {i18n.language === "ar"
              ? "NCMC - الصفحة الرئيسية"
              : "NCMC - Home "}
          </title>
          <meta name="description" content="description" />
          <link
            rel="preload"
            href="./assets/images/Banner1.png"
            as="image"
          />
          <link
            rel="preload"
            href="./assets/images/Banner4.png"
            as="image"
          />
          <link
            rel="preload"
            href="./assets/images/Banner7.png"
            as="image"
          />
          <link
            rel="preload"
            href="./assets/images/ServicesImages/ManagementConsulting.png"
            as="image"
          />
          <link
            rel="preload"
            href="./assets/images/ServicesImages/Concierge.png"
            as="image"
          />
          <link
            rel="preload"
            href="./assets/images/ServicesImages/OrganizingExhibitions.png"
            as="image"
          />
        </Helmet>
        <Banner />
        <main id="main">
          <ServiceContainer />
          <ContactUsSection />
          <br />
          <Accordions />
        </main>
      </div>
    </>
  );
}

export default home;