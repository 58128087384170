import "../styles/serviceContainer.css";
import { useTranslation } from "react-i18next";
import { Typography, Box, Paper, Button } from "@mui/material";
import { Link } from "react-router-dom";

function serviceContainer() {
  const { i18n } = useTranslation();

  const papersService = [
    {
      title:
        i18n.language === "en"
          ? "Management Consulting Services"
          : "خدمات الاستشارات الإدارية",
      image: "../assets/images/ServicesImages/ManagementConsulting.png",
      description:
        i18n.language === "en"
          ? "We provide administrative consulting services such as decision-making support, company restart and rehabilitation."
          : "نقدم خدمات للاستشارات الإدارية, نساعدك في إتخاذ القرارات, إعادة تشغيل وتأهيل الشركات.",
      // path: "/Services/Management-Consulting",
    },
    {
      title: i18n.language === "en" ? "Concierge Services" : "خدمات كونسيرج",
      image: "../assets/images/ServicesImages/Concierge.png",
      description:
        i18n.language === "en"
          ? "We offer exclusive services that cater to all your needs and preferences with elegance and high efficiency. From first-class tickets to travel recommendations and much more, live like royalty."
          : "نقدم لك خدمات مميزة تهتم بكافة احتياجاتك وتوجهاتك بأناقة وكفاءة عالية. من تذاكر درجة اولى إلى توصيات سياحية والكثير، عيش كملك.",
      path: "/Services/Concierge",
    },
    {
      title:
        i18n.language === "en"
          ? "Organizing exhibitions & conferences"
          : "تنظيم المعارض والمؤتمرات",
      image: "../assets/images/ServicesImages/OrganizingExhibitions.png",
      description:
        i18n.language === "en"
          ? "We offer high-quality exhibition and conference organizing services, ensuring the exceptional organization of exhibitions and conferences that meet your expectations and leave an unforgettable impression."
          : "نحن نقدم خدمات تنظيم المعارض والمؤتمرات بمهنية عالية، حيث نضمن تنظيم المعارض والمؤتمرات بأسلوب استثنائي يلبي توقعاتك ويترك انطباعًا لا يُنسى.",
      // path: "/Services/Organizing-Exhibitions",
    },
  ];

  return (
    <Box
      className="serviceContainerParent"
      sx={{
        position: "relative",
        zIndex: "50",
        bottom: { xl: "21rem", sm: "16rem", xs: "16rem" },
      }}
    >
      <Box
        className="parentOfServices"
        sx={{
          display: "flex",
          flexWrap: "wrap",
          width: "100vw",
          flexDirection: i18n.language === "ar" ? "row-reverse !important" : "row !important",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {papersService.map((item) => {
          return (
            <Paper
              key={item.image}
              variant="outlined"
              sx={{
                margin: "1.5rem",
              }}
              className="paperBox"
            >
              {item.path ? (
                <Link
                  to={item.path}
                  style={{
                    textDecoration: "none",
                    cursor: "pointer"
                  }}
                >
                  <Box component="img" src={item.image} srcSet={item.image} alt="serviceImage" loading="lazy" className="serviceImage" />
                  <Box className="gradientServiceImage" />
                </Link>
              ) : (
                <>
                  <Box component="img" src={item.image} srcSet={item.image} alt="serviceImage" loading="lazy" className="serviceImage" />
                  <Box className="gradientServiceImage" />
                </>
              )}
              <Box className="parentBox" sx={{
                mt: "-4rem",
                position: "relative",
                zIndex: "58",
                padding: "0 1rem"
              }}>
                <br />
                <Typography
                  variant="h5"
                  dir={i18n.language === "ar" ? "rtl" : "ltr"}
                  sx={{
                    mt: "0.3rem",
                    textAlign: "start",
                    color: "#fff !important",
                    fontSize: {
                      md: "1.4rem",
                      sm: "1.3rem",
                      bx: "1.2rem",
                      xs: "1rem",
                    },
                  }}
                >
                  {item.title}
                </Typography>
                <Typography
                  variant="h6"
                  component="p"
                  sx={{
                    mt: "13px",
                    color: "#fff",
                    height: item.path ? "107px" : "120px",
                    fontSize: {
                      md: "1rem",
                      xs: "0.8rem",
                    },
                  }}
                  dir={i18n.language === "ar" ? "rtl" : "ltr"}
                >
                  {item.description}
                </Typography>
              </Box>
              {item.path && (
                <>
                  <br />
                  <br />
                  <Link
                    to={item.path}
                    style={{
                      textDecoration: "none",
                    }}
                  >

                    <Button
                      variant="outlined"
                      className="OrderNowBtn"
                      sx={{
                        borderRadius: "30px",
                        textTransform: "capitalize",
                        top: "-2.5rem",
                        borderColor: "#eee !important",
                        color: "#fff",
                        width: "95%",
                        display: "block",
                        margin: "0 auto",
                        fontSize: {
                          md: "18px !important",
                          sm: "16px !important",
                          bx: "14px !important",
                          xs: "12px !important",
                        },
                      }}
                    >
                      {i18n.language === "ar" ? "اعرف اكثر" : "More Info"}
                    </Button>
                  </Link>
                </>
              )}
            </Paper>
          );
        })}
      </Box>
    </Box >
  );
}

export default serviceContainer;
