import "../styles/accordions.css";
import { useTranslation } from "react-i18next";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/material";

export default function CustomizedAccordions() {
  const { i18n } = useTranslation();

  return (
    <Box
      className="AccordionContainer"
      sx={{
        marginTop: "5rem",
        padding: {
          xl: "5rem 4rem 3rem 4rem",
          md: "5rem 3.5rem 3rem 3.5rem",
          bx: "5rem 2rem 3rem 2rem",
          xs: "5rem 0rem 3rem 0rem",
        },
      }}
    >
      <Typography
        variant="h2"
        sx={{
          color: "#fff",
          textAlign: "center",
          margin: "3rem 0",
          fontSize: {
            xl: "3.75rem",
            lg: "3.55rem",
            md: "3.22rem",
            as: "3.00rem",
            sm: "2.66rem",
            bx: "2.33rem",
            xs: "2.00rem",
          },
        }}
        dir={i18n.language === "ar" ? "rtl" : "ltr"}
      >
        {i18n.language === "ar" ? "الأسئلة الشائعة" : "FAQs"}
      </Typography>
      <br />
      <Accordion sx={{ my: 1 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          dir={i18n.language === "ar" ? "rtl" : "ltr"}
        >
          <Typography
            component="p"
            dir={i18n.language === "ar" ? "rtl" : "ltr"}
            sx={{
              margin: "0.8rem 1rem",
              fontFamily: "Tajawal !Important"
            }}
          >
            {i18n.language === "ar"
              ? "ما هي خدمات الرعاية المنزلية التي تقدمونها؟"
              : "What home care services do you offer?"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography dir={i18n.language === "ar" ? "rtl" : "ltr"} sx={{ fontFamily: "El Messiri !Important" }}>
            {i18n.language === "ar"
              ? "نحن نقدم خدمات رعاية منزلية شاملة تشمل الرعاية الصحية والمساعدة اليومية مثل رعاية كبار السن والمرضى والعناية الشخصية."
              : "We provide comprehensive home care services, including healthcare and daily assistance, such as care for seniors, patients, and personal care."}
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ my: 1 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          dir={i18n.language === "ar" ? "rtl" : "ltr"}
        >
          <Typography
            component="p"
            dir={i18n.language === "ar" ? "rtl" : "ltr"}
            sx={{
              margin: "0.8rem 1rem",
              fontFamily: "Tajawal !Important"
            }}
          >
            {i18n.language === "ar"
              ? "هل توفرون رعاية منزلية على مدار الساعة؟"
              : "Do you provide 24/7 home care?"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography dir={i18n.language === "ar" ? "rtl" : "ltr"} sx={{ fontFamily: "El Messiri !Important" }}>
            {i18n.language === "ar"
              ? "نعم، نحن نوفر خدمات الرعاية المنزلية على مدار الساعة لتلبية احتياجات العملاء على مدار اليوم."
              : "Yes, we offer 24/7 home care services to meet the needs of our clients around the clock."}
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ my: 1 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          dir={i18n.language === "ar" ? "rtl" : "ltr"}
        >
          <Typography
            component="p"
            dir={i18n.language === "ar" ? "rtl" : "ltr"}
            sx={{
              margin: "0.8rem 1rem",
              fontFamily: "Tajawal !Important"
            }}
          >
            {i18n.language === "ar"
              ? "ما هي مؤهلات الممرضات والعاملين لديكم؟"
              : "What qualifications do your nurses and caregivers have?"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography dir={i18n.language === "ar" ? "rtl" : "ltr"} sx={{ fontFamily: "El Messiri !Important" }}>
            {i18n.language === "ar"
              ? "نحن نعمل فقط مع ممرضات ومقدمي رعاية مؤهلين وذوي خبرة، يمتلكون المعرفة اللازمة لتقديم خدمات رعاية عالية الجودة."
              : "We only work with qualified and experienced nurses and caregivers who possess the necessary knowledge to deliver high-quality care.    "}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
