import { useState, useEffect } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import "../styles/header.css";
import { ArrowDropDown } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  IconButton,
  MenuItem,
  useTheme,
  ListItemText,
  Menu,
} from "@mui/material";
import { useTranslation } from "react-i18next";

function Header() {
  const { i18n } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  // navbar menu mobile
  const [anchorElMB, setAnchorElMB] = useState(null);
  const openMB = Boolean(anchorElMB);
  const handleClickMB = (event) => {
    setAnchorElMB(event.currentTarget);
  };
  const handleCloseMB = () => {
    setAnchorElMB(null);
  };

  // navbar services menu mobile
  const [anchorElMBSR, setAnchorElMBSR] = useState(null);
  const openMBSR = Boolean(anchorElMBSR);
  const handleClickMBSR = (event) => {
    setAnchorElMBSR(event.currentTarget);
  };
  const handleCloseMBSR = () => {
    setAnchorElMBSR(null);
  };

  return (
    <>
      <Box
        component="div"
        sx={{
          mb: {
            xl: "2.8rem",
            lg: "2.8rem",
          },
        }}
        className="headerDiv"
      >
        {/* header */}
        <Box
          component="header"
          className="transparentHeader"
          sx={{
            backgroundImage:
              theme.palette.mode === "light"
                ? "linear-gradient(#ffffff86, transparent)"
                : "linear-gradient(#00000086, transparent)",
          }}
        >
          {/* logo */}
          <Link to={"/"}>
            <div className="imgDiv">
              <Box
                component="img"
                className="HeaderImg"
                src="../assets/images/ncmcLogoTextV7.png"
                alt="logo"
                aria-label="logo"
              />
            </div>
          </Link>
          {/* navbars ---> */}

          {/* desktop */}
          <div className="NavbarParent">
            <nav className="navbarHeader">
              <Box
                sx={{
                  color:
                    theme.palette.mode === "dark"
                      ? "#fff !important"
                      : "#000 !important",
                }}
                className="LinksUl"
                component="ul"
              >
                {i18n.language === "ar" && (
                  <>
                    <Box sx={{ mx: "3rem !important", display: "flex" }}>
                      <NavLink to="/Home" className="linkLi">
                        <li>{i18n.language === "ar" && "الرئيسية"}</li>
                      </NavLink>
                      <NavLink to="/Services/Concierge" className="linkLi">
                        <li>{i18n.language === "ar" && "قسم الكونسيرج"}</li>
                      </NavLink>
                      <Box
                        className="linkLi"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        aria-controls={openMBSR ? "services-menu2" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openMBSR ? "true" : undefined}
                        onClick={handleClickMBSR}
                      >
                        <li>{i18n.language === "ar" && "الخدمات"}</li>
                        <ArrowDropDown sx={{ fontSize: 30, opacity: 0.65 }} />
                      </Box>
                      <NavLink to="/Contact" className="linkLi">
                        <li>{i18n.language === "ar" && "تواصل معنا"}</li>
                      </NavLink>
                    </Box>

                    <Box
                      className="linkLi"
                      onClick={() => {
                        i18n.language === "ar" ? i18n.changeLanguage("en") : i18n.changeLanguage("ar")
                      }}
                    >
                      <li>{i18n.language === "ar" ? "English" : "العربية"}</li>
                    </Box>
                  </>
                )}
                {i18n.language === "en" && (
                  <>
                    <Box sx={{ mx: "3rem !important", display: "flex" }}>
                      <NavLink to="/Home" className="linkLi">
                        <li>{i18n.language === "en" && "Home"}</li>
                      </NavLink>
                      <NavLink to="/Services/Concierge" className="linkLi">
                        <li>{i18n.language === "en" && "Concierge Department"}</li>
                      </NavLink>
                      <Box
                        className="linkLi"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        aria-controls={openMBSR ? "services-menu2" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openMBSR ? "true" : undefined}
                        onClick={handleClickMBSR}
                      >
                        <li>{i18n.language === "en" && "Services"}</li>
                        <ArrowDropDown sx={{ fontSize: 30, opacity: 0.65 }} />
                      </Box>
                      <NavLink to="/Contact" className="linkLi">
                        <li>{i18n.language === "en" && "Contact us"}</li>
                      </NavLink>
                    </Box>
                    <Box
                      className="linkLi"
                      onClick={() => {
                        i18n.language === "ar" ? i18n.changeLanguage("en") : i18n.changeLanguage("ar")
                      }}
                    >
                      <li>{i18n.language === "en" ? "العربية" : "English"}</li>
                    </Box>
                  </>
                )}
              </Box>
            </nav>
          </div>

          {/* other items inside navbar */}
          <div className="NavBarIcons">
            <IconButton
              className="barsIcon"
              aria-label="bars button"
              aria-controls={openMB ? "basic-menuMobile" : undefined}
              aria-haspopup="true"
              aria-expanded={openMB ? "true" : undefined}
              onClick={handleClickMB}
              sx={{
                color:
                  theme.palette.mode === "dark"
                    ? "#fff !important"
                    : "#000 !important",
              }}
            >
              <MenuIcon sx={{ fontSize: 45 }} />
            </IconButton>
            <Menu
              id="basic-menuMobile"
              anchorEl={anchorElMB}
              open={openMB}
              dir={i18n.language === "ar" ? "rtl" : "ltr"}
              onClose={handleCloseMB}
              sx={{
                display: { xs: "flex", bb: "none" },
              }}
            >
              <MenuItem
                onClick={() => {
                  navigate("/Home");
                  handleCloseMB();
                }}
              >
                <ListItemText
                  className="menuLi"
                  sx={{
                    margin: "0 0.3rem",
                    opacity: i18n.language === "en" ? "1" : "0.7",
                  }}
                >
                  {i18n.language === "ar" ? "الصفحة الرئيسية" : "Home"}
                </ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate("/Services/Concierge");
                  handleCloseMB();
                }}
              >
                <ListItemText
                  className="menuLi"
                  sx={{
                    margin: "0 0.3rem",
                    opacity: i18n.language === "en" ? "1" : "0.7",
                  }}
                >
                  {i18n.language === "ar" ? "قسم الكونسيرج" : "Concierge Department"}
                </ListItemText>
              </MenuItem>
              <MenuItem
                aria-label="open services menu"
                aria-controls={openMBSR ? "services-menu2" : undefined}
                aria-haspopup="true"
                aria-expanded={openMBSR ? "true" : undefined}
                onClick={(event) => {
                  handleClickMBSR(event);
                  // handleCloseMB();
                }}
              >
                <ListItemText
                  className="menuLi"
                  sx={{
                    margin: "0 0.3rem",
                    opacity: i18n.language === "en" ? "1" : "0.7",
                  }}
                >
                  {i18n.language === "ar" ? "الخدمات" : "Services"}
                </ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate("/Contact");
                  handleCloseMB();
                }}
              >
                <ListItemText
                  className="menuLi"
                  sx={{
                    margin: "0 0.3rem",
                    opacity: i18n.language === "en" ? "1" : "0.7",
                  }}
                >
                  {i18n.language === "ar"
                    ? "تواصل معنا"
                    : "Contact with us"}
                </ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  i18n.language === "ar" ? i18n.changeLanguage("en") : i18n.changeLanguage("ar")
                  handleCloseMB();
                }}
              >
                <ListItemText
                  className="menuLi"
                  sx={{
                    margin: "0 0.3rem",
                    opacity: i18n.language === "en" ? "1" : "0.7",
                  }}
                >
                  {i18n.language === "en" ? "العربية" : "English"}
                </ListItemText>
              </MenuItem>

            </Menu>
            {/* Services Menu */}
            <Menu
              id="services-menu2"
              anchorEl={anchorElMBSR}
              open={openMBSR}
              dir={i18n.language === "ar" ? "rtl" : "ltr"}
              onClose={handleCloseMBSR}
            >
              <MenuItem
                onClick={() => {
                  handleCloseMBSR();
                  handleCloseMB();
                }}
              >
                <ListItemText
                  className="menuLi"
                  sx={{
                    margin: "0 1rem",
                    opacity: 0.7,
                  }}
                >
                  {i18n.language === "ar" &&
                    "خدمات الاستشارات الإدارية"}
                  {i18n.language === "en" &&
                    "Management Consulting Services"}
                </ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate("/Services/Concierge");
                  handleCloseMBSR();
                  handleCloseMB();
                }}
              >
                <ListItemText
                  className="menuLi"
                  sx={{
                    margin: "0 1rem",
                    opacity: 0.7,
                  }}
                >
                  {i18n.language === "ar" && "خدمات الكونسيرج"}
                  {i18n.language === "en" && "Concierge Services"}

                </ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCloseMBSR();
                  handleCloseMB();
                }}
              >
                <ListItemText
                  className="menuLi"
                  sx={{
                    margin: "0 1rem",
                    opacity: 0.7,
                  }}
                >
                  {i18n.language === "ar" &&
                    "تنظيم المعارض والمؤتمرات"}
                  {i18n.language === "en" &&
                    "Organizing exhibitions and conferences"}
                </ListItemText>
              </MenuItem>
            </Menu>
          </div>
        </Box>
      </Box>
      <br />
    </>
  );
}

export default Header;