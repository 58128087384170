import { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";
import "../styles/footer.css";
import { useTranslation } from "react-i18next";
import {
  Box,
  Divider,
  Stack,
  MenuItem,
  ListItemText,
  Menu,
} from "@mui/material";
import { EmailOutlined, ArrowBack, ArrowForward } from "@mui/icons-material";

function Footer() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  // navbar services menu mobile
  const [anchorElMBSR, setAnchorElMBSR] = useState(null);
  const openMBSR = Boolean(anchorElMBSR);
  const handleClickMBSR = (event) => {
    setAnchorElMBSR(event.currentTarget);
  };
  const handleCloseMBSR = () => {
    setAnchorElMBSR(null);
  };

  return (
    <>
      <div className="footer-basic">
        <footer>
          {/* Services Menu */}
          <Menu
            id="services-menu2"
            anchorEl={anchorElMBSR}
            open={openMBSR}
            dir={i18n.language === "ar" ? "rtl" : "ltr"}
            onClose={handleCloseMBSR}
          >
            <MenuItem
              onClick={() => {
                handleCloseMBSR();
              }}
            >
              <ListItemText
                className="menuLi"
                sx={{
                  margin: "0 1rem",
                  opacity: 0.7,
                }}
              >
                {i18n.language === "ar" &&
                  "خدمات الاستشارات الإدارية"}
                {i18n.language === "en" &&
                  "Management Consulting Services"}
              </ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/Services/Concierge");
                handleCloseMBSR();
              }}
            >
              <ListItemText
                className="menuLi"
                sx={{
                  margin: "0 1rem",
                  opacity: 0.7,
                }}
              >
                {i18n.language === "ar" && "خدمات الكونسيرج"}
                {i18n.language === "en" && "Concierge Services"}

              </ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleCloseMBSR();
              }}
            >
              <ListItemText
                className="menuLi"
                sx={{
                  margin: "0 1rem",
                  opacity: 0.7,
                }}
              >
                {i18n.language === "ar" &&
                  "تنظيم المعارض والمؤتمرات"}
                {i18n.language === "en" &&
                  "Organizing exhibitions and conferences"}
              </ListItemText>
            </MenuItem>
          </Menu>
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" color="#ccc" flexItem />}
            spacing={6}
            sx={{
              margin: "3rem 5rem",
              justifyContent: "center",
              display: { xs: "none", lg: "flex" },
            }}
            dir={i18n.language === "ar" ? "rtl" : "ltr"}
          >
            <Box sx={{ margin: "0 6rem !important" }}>
              <Typography
                variant="h5"
                sx={{
                  fontSize: {
                    xl: "1.3rem !important",
                    lg: "1.2rem !important",
                  },
                }}
              >
                {i18n.language === "ar" ? "الصفحات الرئيسية" : "Home Pages"}
              </Typography>
              <Stack
                direction="column"
                spacing={2}
                dir={i18n.language === "ar" ? "rtl" : "ltr"}
              >
                <ul
                  className="list-inline"
                  dir={i18n.language === "ar" ? "rtl" : "ltr"}
                >
                  <li className="list-inline-item">
                    <Link to="/Home">
                      {i18n.language === "ar" ? "الرئيسية" : "Home"}
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Box component="a" sx={{ cursor: "pointer" }}
                      aria-controls={openMBSR ? "services-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openMBSR ? "true" : undefined}
                      onClick={handleClickMBSR}>
                      {i18n.language === "ar" ? "الخدمات" : "Services"}
                    </Box>
                  </li>
                  <br />
                  <li className="list-inline-item">
                    <Link to="/Contact">
                      {i18n.language === "ar"
                        ? "تواصل معنا"
                        : "Contact with us"}
                    </Link>
                  </li>
                </ul>
              </Stack>
            </Box>
            <Box sx={{ margin: "0 6rem" }}>
              <Typography
                variant="h5"
                sx={{
                  fontSize: {
                    xl: "1.3rem !important",
                    lg: "1.2rem !important",
                  },
                }}
              >
                {i18n.language === "ar" ? "تعرف علينا اكثر" : "Know Us More"}
              </Typography>
              <Box
                sx={{
                  marginRight: i18n.language === "ar" ? "-1rem" : "unset",
                  marginLeft: i18n.language === "en" ? "-1rem" : "unset",
                  marginTop: "0.25rem",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }} dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                  <Box sx={{ display: "flex", alignItems: "center", margin: "0 0.5rem" }} dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                    <EmailOutlined sx={{ fontSize: 40, color: "#fff", bgcolor: "#1c4f52", borderRadius: "50%", padding: "7px", mx: 2 }} />
                    <a href="mailto:info@ncmc.com.sa" className="ContactP">
                      <Typography variant="caption" color="#fff" sx={{ fontFamily: "Cairo", fontSize: 16 }}>info@ncmc.com.sa</Typography>
                    </a>
                  </Box>
                  <Link to="/Contact" className="ContactP">
                    <Box sx={{ display: "flex", alignItems: "center", margin: "0 2rem" }}>
                      <Typography
                        variant="h5"
                        sx={{
                          color: "#fff !important",
                          textAlign: i18n.language === "ar" ? "right" : "left",
                          margin: "20px 7px",
                          fontSize: 16,
                        }}
                        dir={i18n.language === "ar" ? "rtl" : "ltr"}
                      >
                        {i18n.language === "ar"
                          ? "المزيد من المعلومات"
                          : "More Info"}
                      </Typography>
                      {i18n.language === "ar"
                        ? <ArrowBack sx={{ color: "#fff" }} />
                        : <ArrowForward sx={{ color: "#fff" }} />}
                    </Box>
                  </Link>
                </Box>
              </Box>
            </Box>
          </Stack>
          <Stack
            direction="row"
            spacing={"65px"}
            sx={{
              margin: "3rem 0rem",
              justifyContent: "center",
              display: { md: "flex", lg: "none", xs: "none" },
            }}
            dir={i18n.language === "ar" ? "rtl" : "ltr"}
          >
            <Box sx={{ margin: "0 6rem !important" }}>
              <Typography
                variant="h5"
                sx={{
                  fontSize: {
                    xl: "1.3rem !important",
                    lg: "1.2rem !important",
                  },
                }}
              >
                {i18n.language === "ar" ? "الصفحات الرئيسية" : "Home Pages"}
              </Typography>
              <Stack
                direction="column"
                spacing={2}
                dir={i18n.language === "ar" ? "rtl" : "ltr"}
              >
                <ul
                  className="list-inline"
                  dir={i18n.language === "ar" ? "rtl" : "ltr"}
                >
                  <li className="list-inline-item">
                    <Link to="/Home">
                      {i18n.language === "ar" ? "الرئيسية" : "Home"}
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Box component="a" sx={{ cursor: "pointer" }} aria-controls={openMBSR ? "services-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openMBSR ? "true" : undefined}
                      onClick={handleClickMBSR}>
                      {i18n.language === "ar" ? "الخدمات" : "Services"}
                    </Box>
                  </li>
                  <br />
                  <li className="list-inline-item">
                    <Link to="/Contact">
                      {i18n.language === "ar"
                        ? "تواصل معنا"
                        : "Contact with us"}
                    </Link>
                  </li>
                </ul>
              </Stack>
            </Box>
            <Box sx={{ margin: "0 6rem" }}>
              <Typography
                variant="h5"
                sx={{
                  fontSize: {
                    xl: "1.3rem !important",
                    lg: "1.2rem !important",
                  },
                }}
              >
                {i18n.language === "ar" ? "تعرف علينا اكثر" : "Know Us More"}
              </Typography>
              <Box
                sx={{
                  marginRight: i18n.language === "ar" ? "-1rem" : "unset",
                  marginLeft: i18n.language === "en" ? "-1rem" : "unset",
                  marginTop: "0.25rem",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }} dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                  <Box sx={{ display: "flex", alignItems: "center", margin: "0 0.5rem" }} dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                    <EmailOutlined sx={{ fontSize: 40, color: "#fff", bgcolor: "#1c4f52", borderRadius: "50%", padding: "7px", mx: 2 }} />
                    <a href="mailto:info@ncmc.com.sa" className="ContactP">
                      <Typography variant="caption" color="#fff" sx={{ fontFamily: "Cairo", fontSize: 16 }}>info@ncmc.com.sa</Typography>
                    </a>
                  </Box>
                  <Link to="/Contact" className="ContactP">
                    <Box sx={{ display: "flex", alignItems: "center", margin: "0 2rem" }}>
                      <Typography
                        variant="h5"
                        sx={{
                          color: "#fff !important",
                          textAlign: i18n.language === "ar" ? "right" : "left",
                          margin: "20px 7px",
                          fontSize: 16,
                        }}
                        dir={i18n.language === "ar" ? "rtl" : "ltr"}
                      >
                        {i18n.language === "ar"
                          ? "المزيد من المعلومات"
                          : "More Info"}
                      </Typography>
                      {i18n.language === "ar"
                        ? <ArrowBack sx={{ color: "#fff" }} />
                        : <ArrowForward sx={{ color: "#fff" }} />}
                    </Box>
                  </Link>
                </Box>
              </Box>
            </Box>
          </Stack>
          <Stack
            direction="column"
            spacing={"65px"}
            sx={{
              margin: { as: "3rem 5rem", xs: "3rem 2rem" },
              justifyContent: "center",
              display: { md: "none", lg: "none", xs: "flex" },
            }}
            dir={i18n.language === "ar" ? "rtl" : "ltr"}
          >
            <Box sx={{ margin: "2rem 0 !important" }}>
              <Typography
                variant="h5"
                sx={{
                  fontSize: {
                    xl: "1.3rem !important",
                    lg: "1.2rem !important",
                  },
                }}
              >
                {i18n.language === "ar" ? "الصفحات الرئيسية" : "Home Pages"}
              </Typography>
              <Stack
                direction="column"
                spacing={2}
                dir={i18n.language === "ar" ? "rtl" : "ltr"}
              >
                <ul
                  className="list-inline"
                  dir={i18n.language === "ar" ? "rtl" : "ltr"}
                >
                  <li className="list-inline-item">
                    <Link to="/Home">
                      {i18n.language === "ar" ? "الرئيسية" : "Home"}
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Box component="a" sx={{ cursor: "pointer" }} aria-controls={openMBSR ? "services-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openMBSR ? "true" : undefined}
                      onClick={handleClickMBSR}>
                      {i18n.language === "ar" ? "الخدمات" : "Services"}
                    </Box>
                  </li>
                  <br />
                  <li className="list-inline-item">
                    <Link to="/Contact">
                      {i18n.language === "ar"
                        ? "تواصل معنا"
                        : "Contact with us"}
                    </Link>
                  </li>
                </ul>
              </Stack>
            </Box>
            <Box sx={{ margin: "2rem 0" }}>
              <Typography
                variant="h5"
                sx={{
                  fontSize: {
                    xl: "1.3rem !important",
                    lg: "1.2rem !important",
                  },
                }}
              >
                {i18n.language === "ar" ? "تعرف علينا اكثر" : "Know Us More"}
              </Typography>
              <Box
                sx={{
                  marginRight: i18n.language === "ar" ? "-1rem" : "unset",
                  marginLeft: i18n.language === "en" ? "-1rem" : "unset",
                  marginTop: "0.25rem",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }} dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                  <Box sx={{ display: "flex", alignItems: "center", margin: "0 0.5rem" }} dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                    <EmailOutlined sx={{ fontSize: 40, color: "#fff", bgcolor: "#1c4f52", borderRadius: "50%", padding: "7px", mx: 2 }} />
                    <a href="mailto:info@ncmc.com.sa" className="ContactP">
                      <Typography variant="caption" color="#fff" sx={{ fontFamily: "Cairo", fontSize: 16 }}>info@ncmc.com.sa</Typography>
                    </a>
                  </Box>
                  <Link to="/Contact" className="ContactP">
                    <Box sx={{ display: "flex", alignItems: "center", margin: "0 1rem" }}>
                      <Typography
                        variant="h5"
                        sx={{
                          color: "#fff !important",
                          textAlign: i18n.language === "ar" ? "right" : "left",
                          margin: "20px 7px",
                          fontSize: 16,
                        }}
                        dir={i18n.language === "ar" ? "rtl" : "ltr"}
                      >
                        {i18n.language === "ar"
                          ? "المزيد من المعلومات"
                          : "More Info"}
                      </Typography>
                      {i18n.language === "ar"
                        ? <ArrowBack sx={{ color: "#fff" }} />
                        : <ArrowForward sx={{ color: "#fff" }} />}
                    </Box>
                  </Link>
                </Box>
              </Box>
            </Box>
          </Stack>
          <Typography
            className="copyright"
            dir={i18n.language === "ar" ? "rtl" : "ltr"}
          >
            {i18n.language === "ar" &&
              `جميع الحقوق محفوظة ${new Date().getFullYear()} © لشركة NCMC`}
            {i18n.language === "en" &&
              `All rights reserved © ${new Date().getFullYear()} NCMC`}
          </Typography>
          <br />
          <br />
        </footer>
      </div>
    </>
  );
}

export default Footer;