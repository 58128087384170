import { useState } from "react";
import "../styles/banner.css";
import { useTranslation } from "react-i18next";
import { useTheme, Typography, Box } from "@mui/material";

function Banner() {
  const { i18n } = useTranslation();
  const theme = useTheme();

  const [bannerImg] = useState("../assets/images/Banner5.png");

  return (
    <div className="banner main">
      <div className="background">
        <Box className="content">
          <Box
            className="contaienr"
            dir={i18n.language === "ar" ? "rtl" : "ltr"}
            sx={{
              right: "50px",
              left: "50px",
              textAlign: "center",
            }}
          >
            <Box
              sx={{
                opacity: "1",
                transition: "all 0.6s",
                zIndex: "55",
                mt: "5rem",
              }}
            >
              <Typography
                variant="h1"
                color="#fff"
                sx={{
                  fontSize: {
                    xl: "4.5rem",
                    lg: "4rem",
                    md: "3.5rem",
                    sm: "3rem",
                    xs: "2rem",
                  },
                }}
              >
                {i18n.language === "en"
                  ? "Concierge Services"
                  : "خدمات الكونسيرج"}
              </Typography>
            </Box>
          </Box>
          {i18n.language === "ar" && (
            <Box
              className="StayGradient"
              sx={{
                right: "0",
                backgroundImage:
                  theme.palette.mode === "light"
                    ? "linear-gradient(to right, transparent, #ffffff91)"
                    : "linear-gradient(to right, transparent, #111)",
                padding: "0 0 100vh 50vw",
              }}
            />
          )}
          {i18n.language === "en" && (
            <Box
              className="StayGradient"
              sx={{
                left: "0",
                backgroundImage:
                  theme.palette.mode === "light"
                    ? "linear-gradient(to left, transparent, #ffffff91)"
                    : "linear-gradient(to left, transparent, #111)",
                padding: "100vh 0 0 50vw",
              }}
            />
          )}
        </Box>
        <Box
          component="img"
          src={bannerImg}
          srcSet={bannerImg}
          alt="bannerImage"
          width="900"
          height="444"
          className="bannerImg"
          sx={{
            width: "100%",
            height: "25rem !important",
            position: "absolute !important",
            opacity: "1",
            zIndex: "5",
          }}
        />
      </div>
    </div>
  );
}

export default Banner;
