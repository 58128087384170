import React from "react";
import { Outlet } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import getDesignTokens from "../context/customTheme";
import ScrollToTop from "../components/utils/ScrollToTop"
import Header from "../components/utils/Header";
import Footer from "../components/utils/Footer";

function Root() {
  const mode = "light"
  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ScrollToTop />
      <div>
      <Header />
        <Outlet />
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default Root;
