import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography } from "@mui/material";
import "./styles/error.css";
import { Link } from "react-router-dom";

function error() {
  const { i18n } = useTranslation();

  return (
    <>
      <div className={`App light`}>
        <Helmet>
          <title>
            {i18n.language === "ar"
              ? "NCMC - هناك خطا ما"
              : "NCMC - There Are Problem"}
          </title>
          <meta name="description" content="description" />
        </Helmet>
        <main id="main">
          <Box sx={{ textAlign: "center", marginBottom: "10rem" }} mt="20vh">
            <Typography variant="h3" className="hashTagText">{i18n.language === "ar" ? "خطأ 404" : "Error 404"}</Typography>
            <br />
            <Typography variant="h2" className="pleaseWaitText" sx={{ fontSize: { xs: "2rem", as: "2.25rem", md: "2.5rem", lg: "3rem" } }}> {i18n.language === "ar" ? "هناك مشكلة في مسارك" : "There Are Problem"}</Typography>
            <br />
            <Link to="/">
              <Button variant="outlined" color="success" sx={{ textDecoration: "none", borderRadius: "30px", fontFamily: "Tajawal", fontWeight: 600, color: "#28938d", borderColor: "#28938d" }}>
                {i18n.language === "ar" ? "العودة الى الرئيسية" : "Back to Home"}
              </Button>
            </Link>
          </Box>
        </main>
      </div>
    </>
  );
}

export default error;