import { useState } from "react";
import "../styles/contactContainer.css";
import { useTranslation } from "react-i18next";
import { Typography, Box, alpha, Button,    useTheme } from "@mui/material";
import TextField from "@mui/material/TextField";
import { EmailOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import emailjs from '@emailjs/browser';
import { LoadingButton } from "@mui/lab";

function serviceContainer() {
  const { i18n } = useTranslation();
  const theme = useTheme();

  const [nameInput, setNameInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [msgInput, setMsgInput] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);

  const inputStyle = {
    '& label.Mui-focused': {
      color: '#A0AAB4',
      fontFamily: 'El Messiri, sans-serif !Important',
      top: "16px",
    },
    '& label': {
      color: '#d5d5d599 !Important',
      top: "16px",
      fontFamily: 'El Messiri, sans-serif !Important',
      direction: i18n.language === "ar" ? "rtl" : "ltr",
      transformOrigin: i18n.language === "ar" ? "top right" : "top left",
      right: i18n.language === "ar" ? "28px" : "unset",
      left: i18n.language === "en" ? "0" : "unset"
    },
    '& .css-7209ej-MuiInputBase-input-MuiFilledInput-input': {
      color: "#fff"
    },
    '& .css-10botns-MuiInputBase-input-MuiFilledInput-input': {
      height: "1.25em"
    },
    '& .MuiFilledInput-root': {
      overflow: 'hidden',
      borderRadius: "6px",
      mt: "14px",
      color: "#fff !Important",
      backgroundColor: '#1A2027',
      border: '2px solid',
      borderColor: '#2D3843',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      '&:hover': {
        backgroundColor: '#1A2027',
      },
      '&.Mui-focused': {
        backgroundColor: '#1A2027',
        boxShadow: `${alpha("#08ede2", 0.25)} 0 0 0 2px`,
        borderColor: "#08ede2"
      },
    },
  }

  // Your EmailJS service ID, template ID, and Public Key
  const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

  const handleClick = async (eo) => {
    eo.preventDefault();
    setIsLoading(true);

    // Create a new object that contains dynamic template params
    const templateParams = {
      from_name: nameInput,
      from_email: emailInput,
      to_name: 'NCMC Messages',
      message: msgInput,
    };

    // Send the email using EmailJS
    emailjs.send(serviceId, templateId, templateParams, publicKey)
      .then(() => {
        setIsLoading(false);
        setIsDone(true);
        setNameInput('');
        setEmailInput('');
        setMsgInput('');
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error sending email:', error);
      });
  };

  return (
    <Box
      className="contactContainerParent"
      sx={{
        position: "relative",
        zIndex: "50",
      }}
    >
      {isDone === false && (
        <>
          <Typography
            variant="h2"
            className="head2"
            sx={{
              textAlign: "start",
              margin: "2rem 2rem 0.5rem 2rem",
              color: "#fff !important",
              fontSize: {
                xl: "3.75rem",
                lg: "3.55rem",
                md: "3.22rem",
                as: "3.00rem",
                sm: "2.66rem",
                bx: "2.33rem",
                xs: "2.00rem",
              },
            }}
            dir={i18n.language === "ar" ? "rtl" : "ltr"}
          >
            {i18n.language === "ar" ? "تواصل معنا" : "Contact Us"}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: i18n.language === "ar" ? "row-reverse" : "row", flexWrap: "wrap", my: "1.5rem", }}>
            <Box sx={{
              textAlign: "center",
              margin: "1rem 1rem",
            }}>
              <Box sx={{ display: "flex", alignItems: "center" }} dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                <EmailOutlined sx={{ fontSize: 40, color: "#fff", bgcolor: "#1c4f52", borderRadius: "50%", padding: "7px", mx: 2 }} />
                <a href="mailto:info@ncmc.com.sa" className="ContactP">
                  <Typography variant="caption" color="#fff" sx={{ fontFamily: "Cairo", fontSize: 16 }}>info@ncmc.com.sa</Typography>
                </a>
              </Box>
            </Box>
            <Box
              sx={{
                mx: "auto",
                display: "flex",
                flexDirection: "column",
                bgcolor: "#1c4f52",
                padding: "2rem 2rem",
                borderRadius: "10px",
                width: { xl: "38%", md: "43%", bx: "80%", xs: "92%" },
              }}
              component="form"
              onSubmit={(eo) => {
                handleClick(eo);
              }}
            >
              <TextField
                InputProps={{ disableUnderline: true }}
                variant="filled"
                dir={i18n.language === "ar" ? "rtl" : "ltr"}
                fullWidth
                required
                sx={inputStyle}
                onChange={(eo) => {
                  setNameInput(eo.target.value);
                }}
                label={
                  i18n.language === "ar" ? "الاسم الكريم" : "Your Name"
                }
              />

              <TextField
                InputProps={{ disableUnderline: true }}
                variant="filled"
                dir={i18n.language === "ar" ? "rtl" : "ltr"}
                fullWidth
                required
                sx={inputStyle}
                type="email"
                onChange={(eo) => {
                  setEmailInput(eo.target.value);
                }}
                label={
                  i18n.language === "ar" ? "البريد الالكتروني" : "Your Email"
                }
              />
              <TextField
                InputProps={{ disableUnderline: true }}
                variant="filled"
                dir={i18n.language === "ar" ? "rtl" : "ltr"}
                fullWidth
                required
                sx={[inputStyle, { '& .MuiFilledInput-root': { borderWidth: "2px !important" } }]}
                multiline
                maxRows={10}
                onChange={(eo) => {
                  setMsgInput(eo.target.value);
                }}
                label={i18n.language === "ar" ? "الرسالة" : "Your Message"}
              />
              <br />
              <LoadingButton
                variant="contained"
                type="submit"
                className="contactUsButton"
                fullWidth
                loading={isLoading}
                sx={{textTransform: "capitalize"}}
              >
                {i18n.language === "ar" ? "إرسال" : "Send"}
              </LoadingButton>
            </Box>
          </Box>
        </>
      )}
      {isDone === true && (
        <>
          <Box sx={{ py: "25vh" }}>
            <Typography
              variant="h2"
              className="head2"
              sx={{
                textAlign: "center",
                margin: "2rem auto",
                color: "#fff !important",
                position: "relative",
                zIndex: "20",
                fontSize: {
                  xl: "3.75rem",
                  lg: "3.55rem",
                  md: "3.22rem",
                  as: "3.00rem",
                  sm: "2.66rem",
                  bx: "2rem",
                  xs: "1.5rem",
                },
              }}
              dir={i18n.language === "ar" ? "rtl" : "ltr"}
            >
              {i18n.language === "ar" ? "شكرا لك على التواصل." : "Thank you for reaching out."}
            </Typography>
            <br />
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Link to="/">
                <Button variant="outlined" color="info" sx={{ textDecoration: "none", borderRadius: "30px", fontFamily: "Tajawal", fontWeight: 600, color: "#fff", borderColor: "#fff !important" }}>
                  {i18n.language === "ar" ? "العودة الى الرئيسية" : "Back to Home"}
                </Button>
              </Link>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}

export default serviceContainer;