import { Helmet } from "react-helmet-async";
import "./styles/services.css";
import { useTranslation } from "react-i18next";
import { Box, useTheme, Typography } from "@mui/material";
import Banner from "../components/banners/ConciergeBanner";

function concierge() {
  const { i18n } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <div className={`App ${theme.palette.mode}`}>
        <Helmet>
          <title>
            {i18n.language === "ar"
              ? "NCMC - خدمات الكونسيرج"
              : "NCMC - Concierge Services"}
          </title>
          <meta name="description" content="description" />
          <link rel="preload" href="./assets/images/Banner5.png" as="image" />
          <link rel="preload" href="./assets/images/ServicesImages/HealthLifeStyle.png" as="image" />
          <link rel="preload" href="./assets/images/ServicesImages/HotelConcierge.png" as="image" />
          <link rel="preload" href="./assets/images/ServicesImages/MedicalAirFly.png" as="image" />
          <link rel="preload" href="./assets/images/ServicesImages/AirFlyOne.png" as="image" />
        </Helmet>
        <Banner />
        <main id="main">
          <Box className="imagesCornerSection" sx={{ mb: "13rem", mt: "-25rem" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", direction: i18n.language === "ar" ? "rtl" : "ltr" }}>
              <Box sx={{ width: "75vw" }}>
                <Typography variant="h4" className="CornerText" sx={{ mx: "3rem", direction: i18n.language === "ar" ? "rtl" : "ltr", fontSize: { xl: "2.125rem", md: "1.8rem", xs: "1.5rem" } }}>{i18n.language === "ar" ? "طيران خاص" : "Private Jet"}</Typography>
                <br />
                <Typography variant="h5" className="CornerText2" sx={{ mx: "3rem", direction: i18n.language === "ar" ? "rtl" : "ltr", fontSize: { xl: "1.8rem", md: "1.5rem", xs: "1rem" } }}>{i18n.language === "ar" ? "طيران اسرع, مريح اكثر, فخم اكثر." : "Faster flying, more comfortable, more luxurious."}</Typography>
              </Box>
              <Box component="img" src="../assets/images/ServicesImages/AirFlyOne.png" alt="CornerImage" loading="lazy" className={i18n.language === "ar" ? "CornerImage2" : "CornerImage1"}></Box>
            </Box>
            <br />
            <br />
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", direction: i18n.language === "en" ? "rtl" : "ltr" }}>
              <Box sx={{ width: "75vw" }}>
                <Typography variant="h4" className="CornerText" sx={{ mx: "3rem", direction: i18n.language === "ar" ? "rtl" : "ltr", fontSize: { xl: "2.125rem", md: "1.8rem", xs: "1.5rem" } }}>{i18n.language === "ar" ? "طبيب او ممرض شخصي" : "Personal Doctor or Nurse"}</Typography>
                <br />
                <Typography variant="h5" className="CornerText2" sx={{ mx: "3rem", direction: i18n.language === "ar" ? "rtl" : "ltr", fontSize: { xl: "1.8rem", md: "1.5rem", xs: "1rem" } }}>{i18n.language === "ar" ? "رعاية صحية  للاطفال, وكبار السن, المعاقين..." : "Health care for children, the elderly, and the disabled..."}</Typography>
              </Box>
              <Box component="img" src="../assets/images/ServicesImages/HealthLifeStyle.png" alt="CornerImage" loading="lazy" dir="ltr" className={i18n.language === "en" ? "CornerImage2" : "CornerImage1"}></Box>
            </Box>
            <br />
            <br />
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", direction: i18n.language === "ar" ? "rtl" : "ltr" }}>
              <Box sx={{ width: "75vw" }}>
                <Typography variant="h4" className="CornerText" sx={{ mx: "3rem", direction: i18n.language === "ar" ? "rtl" : "ltr", fontSize: { xl: "2.125rem", md: "1.8rem", xs: "1.5rem" } }}>{i18n.language === "ar" ? "خدمات تنظيم الحجوزات" : "Reservation Organization Services"}</Typography>
                <br />
                <Typography variant="h5" className="CornerText2" sx={{ mx: "3rem", direction: i18n.language === "ar" ? "rtl" : "ltr", fontSize: { xl: "1.8rem", md: "1.5rem", xs: "1rem" } }}>{i18n.language === "ar" ? ".من ترتيب الحجوزات في الفنادق, والمستشفيات داخل المملكة وخارجها" : "From arranging reservations in hotels and hospitals inside and outside the KSA."}</Typography>
              </Box>
              <Box component="img" src="../assets/images/ServicesImages/HotelConcierge.png" alt="CornerImage" loading="lazy" className={i18n.language === "ar" ? "CornerImage2" : "CornerImage1"}></Box>
            </Box>
            <br />
            <br />
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", direction: i18n.language === "en" ? "rtl" : "ltr" }}>
              <Box sx={{ width: "75vw" }}>
                <Typography variant="h4" className="CornerText" sx={{ mx: "3rem", direction: i18n.language === "ar" ? "rtl" : "ltr", fontSize: { xl: "2.125rem", md: "1.8rem", xs: "1.5rem" } }}>{i18n.language === "ar" ? "إسعاف جوي" : "Air Ambulance"}</Typography>
                {/* <br /> */}
                {/* <Typography variant="h5" className="CornerText2" sx={{ mx: "3rem", direction: i18n.language === "ar" ? "rtl" : "ltr", fontSize: { xl: "1.8rem", md: "1.5rem", xs: "1rem" } }}>{i18n.language === "ar" ? "رعاية صحية  للاطفال, وكبار السن, المعاقين..." : "Health care for children, the elderly, and the disabled..."}</Typography> */}
              </Box>
              <Box component="img" src="../assets/images/ServicesImages/MedicalAirFly.png" alt="CornerImage" loading="lazy" dir="ltr" className={i18n.language === "en" ? "CornerImage2" : "CornerImage1"}></Box>
            </Box>
          </Box>
        </main>
      </div>
    </>
  );
}

export default concierge;