import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";
import ContactContainer from "../components/sections/contactContainer";

function home() {
  const { i18n } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <div className={`App ${theme.palette.mode}`}>
        <Helmet>
          <title>
            {i18n.language === "ar"
              ? "NCMC - تواصل معنا"
              : "NCMC - Contact us"}
          </title>
          <meta name="description" content="description" />
        </Helmet>
        <main id="main" style={{ marginBottom: "4rem" }}>
          <ContactContainer />
        </main>
      </div>
    </>
  );
}

export default home;