import { useEffect, useState } from "react";
import "../styles/banner.css";
import { useTranslation } from "react-i18next";
import { useTheme, Typography, Box, Button } from "@mui/material";
import { Link } from "react-router-dom";

function Banner() {
  const { i18n } = useTranslation();
  const theme = useTheme();

  const [bannerImg, setbannerImg] = useState("../assets/images/Banner1.png");
  const [bennerImgNum, setbennerImgNum] = useState(1);

  const [goMoved, setGoMoved] = useState("")

  const bannerSlider = () => {
    setTimeout(() => {
      setGoMoved("goMoved")
      setbannerImg(
        bannerImg === "../assets/images/Banner1.png"
          ? "../assets/images/Banner7.png"
          : bannerImg === "../assets/images/Banner7.png" ?
            "../assets/images/Banner4.png" :
            bannerImg === "../assets/images/Banner4.png" ?
              "../assets/images/Banner1.png" : null
      );
      setbennerImgNum(bennerImgNum === 1
        ? 2
        : bennerImgNum === 2 ?
          3 :
          bennerImgNum === 3 ?
            1 : null);
    }, 5000);
    setTimeout(() => { setGoMoved("") }, 600)
  }

  useEffect(() => {
    bannerSlider()
  }, [bannerImg]);

  return (
    <div className="banner main">
      <div className="background">
        <Box className="content">
          <Box
            className="contaienr"
            dir={i18n.language === "ar" ? "rtl" : "ltr"}
            sx={{
              right:
                i18n.language === "ar"
                  ? { lg: "5.5rem", sm: "3rem", xs: "1.4rem" }
                  : "unset",
              left:
                i18n.language === "en"
                  ? { lg: "5.5rem", sm: "3rem", xs: "1.4rem" }
                  : "unset",
            }}
          >
            <Box
              sx={{
                opacity: "1",
                transition: "all 0.6s",
                zIndex: "55",
                mt: "4rem",
              }}
            >
              <Typography
                variant="h1"
                color="#fff"
                sx={{
                  fontSize: {
                    xl: "1.8rem",
                    lg: "1.5rem",
                    md: "1rem",
                    xs: "1rem",
                  },
                }}
              >
                {bennerImgNum === 1
                  ? i18n.language === "en" ? "Management Consulting Services" : "خدمات الاستشارات الإدارية"
                  : bennerImgNum === 2 ?
                    i18n.language === "en" ? "Concierge Services" : "خدمات الكونسيرج" :
                    bennerImgNum === 3 ?
                      i18n.language === "en"
                        ? "Organizing exhibitions & conferences"
                        : "تنظيم المعارض والمؤتمرات" : null}
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  width: "60%",
                  fontSize: {
                    xl: "1.4rem",
                    lg: "1.25rem",
                    md: "1rem",
                    xs: "0.75rem",
                  },
                }}
              >
                {bennerImgNum === 1 ? i18n.language === "en"
                  ? "We provide administrative consulting services such as decision-making support, company restart and rehabilitation, and similar services."
                  : "نقدم خدمات للاستشارات الإدارية, نساعدك في إتخاذ القرارات, إعادة تشغيل وتأهيل الشركات."
                  : bennerImgNum === 2 ? i18n.language === "en" ?
                    "We offer exclusive services that cater to all your needs and preferences with elegance and high efficiency. From first-class tickets to travel recommendations and much more, live like royalty."
                    : "نقدم لك خدمات مميزة تهتم بكافة احتياجاتك وتوجهاتك بأناقة وكفاءة عالية. من تذاكر درجة اولى إلى توصيات سياحية والكثير، عيش كملك." :
                    bennerImgNum === 3 ?
                      i18n.language === "en"
                        ? "We offer high-quality exhibition and conference organizing services, ensuring the exceptional organization of exhibitions and conferences that meet your expectations and leave an unforgettable impression."
                        : "نحن نقدم خدمات تنظيم المعارض والمؤتمرات بمهنية عالية، حيث نضمن تنظيم المعارض والمؤتمرات بأسلوب استثنائي يلبي توقعاتك ويترك انطباعًا لا يُنسى." : null}
              </Typography>
              <br />
              {bennerImgNum === 2 && (
                <Link
                  className={`parantOfButton ${goMoved}`}
                  to={bennerImgNum === 2 && "/Services/Concierge"}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <Button
                    variant="outlined"
                    className="OrderNowBtn OrderNowBtn2"
                    sx={{
                      borderRadius: "30px",
                      textTransform: "capitalize",
                      borderColor: "#eee !important",
                      color: "#fff",
                      width: "8rem",
                      fontSize: {
                        xl: "1.1rem !important",
                        lg: "1rem !important",
                        md: "0.75rem !important",
                        xs: "0.75rem !important",
                      },
                    }}
                  >
                    {i18n.language === "ar" ? "اعرف اكثر" : "More Info"}
                  </Button>
                </Link>
              )}
            </Box>
          </Box>
          <Box className="bannerSliderParent">
            <Box className={`bannerSliderSquare ${bennerImgNum === 1 ? "active" : null}`} />
            <Box className={`bannerSliderSquare ${bennerImgNum === 2 ? "active" : null}`} />
            <Box className={`bannerSliderSquare ${bennerImgNum === 3 ? "active" : null}`} />
          </Box>

          {i18n.language === "ar" && (
            <Box
              className="StayGradient"
              sx={{
                right: "0",
                backgroundImage:
                  theme.palette.mode === "light"
                    ? "linear-gradient(to right, transparent, #ffffff91)"
                    : "linear-gradient(to right, transparent, #111)",
                padding: "0 0 100vh 50vw",
              }}
            />
          )}
          {i18n.language === "en" && (
            <Box
              className="StayGradient"
              sx={{
                left: "0",
                backgroundImage:
                  theme.palette.mode === "light"
                    ? "linear-gradient(to left, transparent, #ffffff91)"
                    : "linear-gradient(to left, transparent, #111)",
                padding: "100vh 0 0 50vw",
              }}
            />
          )}
        </Box>
        <Box
          component="img"
          src={bannerImg}
          // srcSet={bannerImg}
          // loading="lazy"
          alt="bannerImage"
          width="900"
          height="444"
          className={`bannerImg ${goMoved}`}
          sx={{
            width: "100vw",
            height: "30rem !important",
            position: "absolute !important",
            opacity: "1",
            zIndex: "5",
          }}
        />
        <Box component="div" className="gradient" />
      </div>
    </div>
  );
}

export default Banner