import "../styles/contactUsSection.css";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { EmailOutlined } from "@mui/icons-material";
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Link, useLocation } from "react-router-dom";

function contactUsSection() {
  const { i18n } = useTranslation();

  return (
    <Box
      className="contactUsSection"
      sx={{
        // marginTop: "6rem",
        marginTop: "-4.5rem",
        padding: {
          xl: "5rem 4rem 3rem 4rem",
          md: "5rem 3.5rem 3rem 3.5rem",
          bx: "5rem 2rem 3rem 2rem",
          xs: "5rem 0rem 3rem 0rem",
        },
      }}
    >
      <Typography
        variant="h2"
        sx={{
          color: "#fff",
          textAlign: i18n.language === "ar" ? "right" : "left",
          margin: "0 3rem",
          fontSize: {
            xl: "3.75rem",
            lg: "3.55rem",
            md: "3.22rem",
            as: "3.00rem",
            sm: "2.66rem",
            bx: "2.33rem",
            xs: "2.00rem",
          },
        }}
        dir={i18n.language === "ar" ? "rtl" : "ltr"}
      >
        {i18n.language === "ar" ? "تواصل معنا" : "Contact Us"}
      </Typography>
      <br />
      <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }} dir={i18n.language === "ar" ? "rtl" : "ltr"}>
        <Box sx={{ display: "flex", alignItems: "center", margin: "0 3.5rem" }} dir={i18n.language === "ar" ? "rtl" : "ltr"}>
          <EmailOutlined sx={{ fontSize: 40, color: "#fff", bgcolor: "#1c4f52", borderRadius: "50%", padding: "7px", mx: 2 }} />
          <a href="mailto:info@ncmc.com.sa" className="ContactP">
            <Typography variant="caption" color="#fff" sx={{ fontFamily: "Cairo", fontSize: 16 }}>info@ncmc.com.sa</Typography>
          </a>
        </Box>
        <Link to="/Contact" className="ContactP">
          <Box sx={{ display: "flex", alignItems: "center", margin: "0 4.25rem" }}>
            <Typography
              variant="h5"
              sx={{
                color: "#fff !important",
                textAlign: i18n.language === "ar" ? "right" : "left",
                margin: "20px 7px",
                fontSize: 16,
              }}
              dir={i18n.language === "ar" ? "rtl" : "ltr"}
            >
              {i18n.language === "ar"
                ? "المزيد من المعلومات"
                : "More Info"}
            </Typography>
            {i18n.language === "ar"
              ? <ArrowBack sx={{ color: "#fff" }} />
              : <ArrowForward sx={{ color: "#fff" }} />}
          </Box>
        </Link>
      </Box>
    </Box>
  );
}

export default contactUsSection;